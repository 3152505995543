<template>
    <div>
        <v-dialog
            v-model="showDialogHold"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    To {{ type == 'H' ? 'hold' : 'resume' }} this project you need to inform a reason
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Reason</label>
                            <v-textarea
                                v-model="reason"
                                rows="4"
                                row-height="23"
                                auto-grow
                                outlined
                            >
                            </v-textarea>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },

            type: {
                type: String,
                default: "H"
            },

            projectOrService: {
                type: String,
                default: "P"
            },

			showDialogHold: {
				default: false
			},
		},
		
        data: () => ({
			
            reason: ""
		}),

        methods: {

            cancel() {
                this.$emit('update:showDialogHold', false);
            },

            async confirm() {
                
                let updateSituationRequest = {
                    id: this.id,
                    situation: this.type == 'H' ? 2 : 1, //Hold or Resume
                    reason: this.reason
                };

                let url = "serviceModule/UpdateProjectSituation";

                if (this.projectOrService == "S") {
                    url = "serviceModule/UpdateServiceSituation";
                }

                let result = await this.$store.dispatch(url, updateSituationRequest);

                if (result.success) {
                    this.showToast("success", "Success!", "Situation updated with successfully!");
                
                    this.$emit('methodConfirmToCall');
                    this.cancel();
                }
            }
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>