<template>
    <div :style="styleHeader">
        <label v-if="description != undefined" :style="styleDescription">{{description}}</label> 
        <label v-if="subDescription != undefined" style="font-size: 10px !important;">
            <br />
            <v-icon v-if="icon != undefined" left color="#5CD7FD">{{icon}}</v-icon>
            {{subDescription}}
        </label>
        <v-divider v-if="showDivider" class="pb-2 mb-1"></v-divider>    
    </div>
</template>

<script>
export default {
    props: {
        icon: String,
        description: String,
        subDescription: String,
        styleDescription: String,
        styleHeader: {
            type: String,
            default: "padding-top: 10px;"
        },            
        showDivider: {
            type: Boolean,
            default: true
        }
    }
}
</script>
