<template>
    <div>
        <v-dialog
            v-model="showDialogActivities"
            transition="dialog-top-transition"
            persistent
            width="1000"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <canvas id="canvas" hidden></canvas>
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    {{ title }}
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-row>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuTargetDate"
                                v-model="mnuTargetDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="300px"
                                min-width="auto"
                                :disabled="allReadOnly"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Target Date</label>
                                    <v-text-field
                                        v-model="targetDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="actionsRegistersRequest.targetDate = parseDate(targetDateFormatted);"
                                        readonly
                                        :disabled="!showTargetDate || allReadOnly"
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="actionsRegistersRequest.targetDate"
                                    no-title
                                    @input="mnuTargetDate = false"
                                    :disabled="!showTargetDate"
                                    :min="lastDateTemporary"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col 
                            v-if="showCompletedDate"
                            cols="12"
                            lg="6"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuCompletedDate"
                                v-model="mnuCompletedDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="300px"
                                min-width="auto"
                                :disabled="allReadOnly"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Completed Date</label>
                                    <v-text-field
                                        v-model="completedDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        @blur="actionsRegistersRequest.completedDate = parseDate(completedDateFormatted);"
                                        :disabled="!showCompletedDate || allReadOnly"
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="actionsRegistersRequest.completedDate"
                                    no-title
                                    @input="mnuCompletedDate = false"
                                    :min="today"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="showDocument"
                    >
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Document</label>
                            <v-file-input
                                v-model="documentFiles" 
                                hint=" "
                                persistent-hint
                                multiple
                                accept=".*"
                                small-chips
                                outlined
                                placeholder="Click here to add"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="addFileToList(documentFiles, documents)"
                                dense
                                :disabled="allReadOnly"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showDocument"
                    >
                        <v-col
                            v-for="(itemDocument, index) in documents.filter(doc => doc.deleted == false)"
                            :key="index"
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                            style="text-align: center; margin-bottom: 20px;"
                        >
                            <v-card
                                class="justify-center"
                                @click="downloadFile(URL_ATTACHMENT, itemDocument.id, itemDocument.fileType, itemDocument.fileName)"
                            >
                                <v-card-text>
                                    <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                    <br />
                                    <span class="imageFileName">{{ itemDocument.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click.stop="" @click="confirmPhotoDelete(itemDocument)">mdi mdi-delete</v-icon>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showSubContractor"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Sub contractor</label>
                            <v-combobox
                                v-model="actionsRegistersRequest.contractorSelected"
                                :items="listContractor"
                                item-text="name"
                                item-value="id"
                                single-line
                                outlined
                                dense
                                :disabled="allReadOnly"
                            ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showUploadPhotos"
                    >
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <canvas id="canvas" hidden></canvas>
                            <label>Photos</label>
                            <v-file-input
                                v-model="activityFilesPhotos" 
                                accept="image/*"
                                small-chips
                                multiple
                                outlined
                                placeholder="Click here to add"
                                prepend-inner-icon="mdi-paperclip"
                                prepend-icon=""
                                @change="resizePreviewImage(activityFilesPhotos, activityPhotos)"
                                dense
                                :disabled="allReadOnly"
                            ></v-file-input>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showUploadPhotos"
                    >
                        <v-col
                            v-for="(itemPhoto, index) in activityPhotos.filter(pht => pht.deleted == false)"
                            :key="index"
                            cols="12"
                            lg="2"
                            md="4"
                            sm="12"
                            style="margin-bottom: 20px;"
                        >
                            <v-card max-width="200">
                                <v-img
                                    :src="itemPhoto.src"
                                    :lazy-src="itemPhoto.src"
                                    aspect-ratio="1"
                                    max-height="200"
                                    max-width="200"
                                >
                                    <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                    </template>
                                </v-img>
                                <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemPhoto)">mdi mdi-delete</v-icon>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showCheckList"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <ContentHeader :description="actionsResponse.checkListTitle" />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col 
                            v-if="showCheckList"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-list>
                                <v-list-item-group
                                    v-model="checkListSelected"
                                    multiple
                                >
                                    <template v-for="(item) in actionsResponse.listCheckList">
                                        <v-list-item
                                            :key="item.id"
                                            :value="item.description"
                                            :disabled="allReadOnly"
                                        >
                                            <template v-slot:default="{ active }">

                                                <v-list-item-action>
                                                    <v-checkbox
                                                        :input-value="active"
                                                    ></v-checkbox>
                                                </v-list-item-action>

                                                <v-list-item-content>
                                                    <v-list-item-title> {{ item.description }} </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-list-item>
                                    </template>
                                </v-list-item-group>
                            </v-list>

                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showApproved"
                    >
                        <v-col 
                            cols="12"
                            lg="3"
                            md="12"
                            sm="12"
                        >
                            <label>Approved ?</label>
                            <br />
                            <v-btn-toggle
                                dense
                            >
                                <v-btn 
                                    class="btnToogleNoAccess"
                                    :disabled="allReadOnly"
                                >
                                    No
                                </v-btn>

                                <v-btn 
                                    class="btnToogle"
                                    :disabled="allReadOnly"
                                >
                                    Yes
                                </v-btn>

                            </v-btn-toggle>
                        </v-col>  
                    </v-row>                  

                    <v-row
                        v-if="showForm"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <ContentHeader 
                                :description="actionsResponse.formTitle" 
                                :subDescription="actionsResponse.formDescription" 
                            />
                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showForm"
                    >
                        <v-expansion-panels popout style="padding-bottom: 20px;">
                            <v-col
                                v-for="(itemQuestion, index) in actionsResponse.listActionsForm" :key="index"
                                style="padding-top: 10px !important;"
                                cols="10"
                                lg="12"
                                md="10"
                                sm="10"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header :hide-actions="itemQuestion.typeAnswer == 3">
                                        <v-row>
                                            <v-col
                                                style="display: flex; align-content: center; align-items: center;"
                                                cols="12"
                                                lg="9"
                                                md="6"
                                                sm="12"
                                            >
                                                <v-icon color="primary" left style="margin-top: -6px;">
                                                    mdi-chat-question-outline
                                                </v-icon>
                                                {{ itemQuestion.question }}
                                            </v-col>
                                            <v-col
                                                v-if="itemQuestion.typeAnswer == 3"
                                                style="display: flex; align-content: center; align-items: center;"
                                                cols="12"
                                                lg="3"
                                                md="6"
                                                sm="12"
                                            >
                                                <v-radio-group 
                                                    v-model="itemQuestion.answer"
                                                    row
                                                    dense
                                                    :rules=[validations.required]
                                                    style="height: 0px; margin-top: -20px;"
                                                    :disabled="allReadOnly"
                                                >
                                                    <v-radio
                                                        label="Yes"
                                                        :value="1"
                                                        color="var(--color__main)"
                                                        @click.stop=""
                                                    ></v-radio>
                                                    <v-radio
                                                        label="No"
                                                        :value="2"
                                                        color="var(--color__main)"
                                                        @click.stop=""
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        
                                        <v-row
                                            v-if="itemQuestion.typeAnswer == 1"
                                        >
                                            <v-col
                                                cols="12"
                                                lg="12"
                                                md="12"
                                                sm="12"
                                            >
                                                <label>Answer</label>
                                                <v-text-field
                                                    v-model="itemQuestion.answer"
                                                    :rules=[validations.required]
                                                    single-line
                                                    outlined
                                                    dense
                                                    :disabled="allReadOnly"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row
                                            v-if="itemQuestion.typeAnswer == 2"
                                        >
                                            <v-col
                                                cols="12"
                                                lg="12"
                                                md="12"
                                                sm="12"
                                            >
                                                <label>Answer</label>
                                                <v-combobox
                                                    v-model="itemQuestion.answer"
                                                    :items="itemQuestion.listAnswer"
                                                    :rules=[validations.required]
                                                    item-text="description"
                                                    item-value="id"
                                                    single-line
                                                    outlined
                                                    dense
                                                    :disabled="allReadOnly"
                                                ></v-combobox>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row
                                            v-if="itemQuestion.typeAnswer == 4"
                                        >
                                            <v-col
                                                cols="12"
                                                lg="12"
                                                md="12"
                                                sm="12"
                                            >
                                                <v-menu
                                                    ref="itemQuestion.mnuAnswerDate"
                                                    v-model="itemQuestion.mnuAnswerDate"
                                                    :close-on-content-click="false"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="300px"
                                                    min-width="auto"
                                                    :disabled="allReadOnly"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <label>Answer</label>
                                                        <v-text-field
                                                            v-model="itemQuestion.answerFormatted"
                                                            prepend-inner-icon="mdi-calendar"
                                                            v-bind="attrs"
                                                            @blur="itemQuestion.answer = parseDate(itemQuestion.answerFormatted);"
                                                            readonly
                                                            outlined
                                                            single-line
                                                            v-on="on"
                                                            dense
                                                            :disabled="allReadOnly"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="itemQuestion.answer"
                                                        no-title
                                                        @input="itemQuestion.mnuAnswerDate = false"
                                                        :min="today"
                                                        color="primary lighten-1"
                                                        :disabled="allReadOnly"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        
                                        <v-row
                                            v-if="itemQuestion.uploadPhoto == 1"
                                        >
                                            <v-col
                                                cols="12"
                                                lg="12"
                                                md="6"
                                                sm="12"
                                            >
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Photos (All Images Types)</label>
                                                        <v-file-input
                                                            v-model="question1FilesPhotos" 
                                                            :rules=[validations.file]
                                                            accept="image/*"
                                                            small-chips
                                                            multiple
                                                            outlined
                                                            placeholder="Click here to add"
                                                            prepend-inner-icon="mdi-paperclip"
                                                            prepend-icon=""
                                                            @change="resizePreviewImage(question1FilesPhotos, question1Photos)"
                                                            dense
                                                            :disabled="allReadOnly"
                                                        ></v-file-input>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in question1Photos.filter(pht => pht.deleted == false)"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="4"
                                                        sm="12"
                                                    >
                                                        <v-card
                                                            @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                        >
                                                            <v-img
                                                                :src="itemPhoto.src"
                                                                :lazy-src="itemPhoto.src"
                                                                aspect-ratio="1"
                                                                max-height="150"
                                                                max-width="150"
                                                                :disabled="allReadOnly"
                                                            >
                                                                <template v-slot:placeholder>
                                                                <v-row
                                                                    class="fill-height ma-0"
                                                                    align="center"
                                                                    justify="center"
                                                                >
                                                                    <v-progress-circular
                                                                        indeterminate
                                                                        color="grey lighten-5"
                                                                    ></v-progress-circular>
                                                                </v-row>
                                                                </template>
                                                            </v-img>
                                                            <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                                                            <v-icon v-if="allReadOnly == false" class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(itemPhoto)">mdi mdi-delete</v-icon>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-col>

                        </v-expansion-panels>

                    </v-row>

                    <v-row
                        v-if="showPanelStages"
                    >
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Panel</label>
                            <v-combobox
                                v-model="panelSelected"
                                :items="listConstructionPanel"
                                item-text="panelDescription"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                @change="filterStages()"
                            ></v-combobox>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="6"
                            md="6"
                            sm="12"
                        >
                            <label>Stage</label>
                            <v-combobox
                                v-model="stageSelected"
                                :items="listConstructionStagesFiltered"
                                item-text="stageDescription"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col 
                            v-if="showNotes"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="notes"
                                rows="2"
                                row-height="15"
                                auto-grow
                                outlined
                                :readonly="readOnlyNotes"
                                :disabled="allReadOnly"
                            >
                            </v-textarea>

                        </v-col>
                    </v-row>

                    <v-row
                        v-if="showNotes"
                    >
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <ContentHeader description="History" />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col 
                            v-if="showNotes"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-data-table
                                :headers="headersNote"
                                :items="actionsRegistersRequest.listNotes"
                                no-data-text="No notes registered"
                                hide-default-footer
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>

                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2"
                            style="background-color: var(--color__silver) !important; color: #ffffff !important"
							outlined
							@click="cancel"
						>
							Close
						</v-btn>

						<v-btn
                            v-if="readOnlyNotes == false"
							class="mx-2"
                            style="background-color: var(--color__in_progress) !important; color: #ffffff !important"
							outlined
                            @click="save"
						>
							Save
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 


        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required, requiredFile } from "@/utilities/Rules";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { URL_ATTACHMENT } from "@/utilities/Enums";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT, EVENT_TYPE } from "@/utilities/Enums";

	export default {

        components: {
            ActionDialog,
            ContentHeader
        },

        mixins: [Helpers],

        props: {
			
            idAux: {
                type: Number,
                default: 0
            },
			
            idServiceProject: {
                type: Number,
                default: 0
            },
			
            statusProject: {
                type: Number,
                default: 0
            },
			
            idPanel: {
                type: Number,
                default: 0
            },
			
            idStage: {
                type: Number,
                default: 0
            },
			
            idAction: {
                type: Number,
                default: 0
            },

            title: {
                type: String,
                default: ""
            },

			showDialogActivities: {
				default: false
			},

			showApproved: {
				default: false
			},

			showPanelStages: {
				default: false
			},

			readOnlyNotes: {
				default: false
			},

			allReadOnly: {
				default: false
			},
		},
		
        data: vm => ({
            
			URL_ATTACHMENT,
            EVENT_TYPE,
			
            actionsResponse: null,
            actionsRegistersRequest: {
                targetDate: vm.today,
                completedDate: vm.today,
                deadlineDate: vm.today,
                contractorSelected: null,
                listNotes: [],
                listEvents: []
            },
            
            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            lastDateTemporary: '2023-01-01', //	- A Ray gostaria de cadastrar datas retroativas por enquanto, durante essa fase de implantação

            targetDateFormatted: vm.formatDate(vm.today),
            mnuTargetDate: false,

            completedDateFormatted: vm.formatDate(vm.today),
            mnuCompletedDate: false,

            documentFiles: [],
            documents: [],

            listContractor: [],

            activityFilesPhotos: [],
            activityPhotos: [],

            checkListSelected: null,
            listCheckList: [],

            notes: "",

            question1FilesPhotos: [],
            question1Photos: [],

            question2FilesPhotos: [],
            question2Photos: [],

            listYesNo: [
                { id: 1, description: 'Yes'},
                { id: 0, description: 'No'},
            ],

            listConstructionPanel: [],
            listConstructionStages: [],
            listConstructionStagesFiltered: [],

            panelSelected: null,
            stageSelected: null,

            itemPhotoToDelete: null,
            
            headersNote: [
                { text: "Date", value: "dateRegisterFormatted", sortable: true },
                { text: "User", value: "userNameRegister", sortable: true },
                { text: "Note", value: "note", sortable: true }
            ],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            validations: {
                file: requiredFile,
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            isAdmin() {
                return this.userLoggedGetters.profile.systemAdmin == 1;
            },

            hasActionResponse() {
                return this.actionsResponse != null && this.actionsResponse != undefined;
            },

            showTargetDate() {
                let show = false;
                
                if (this.hasActionResponse) {

                    if (this.actionsResponse.startDate == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileStartDateSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileStartDateSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }                    
                }

                return show;
            },

            showCompletedDate() {
                let show = false;
                
                if (this.hasActionResponse) {

                    if (this.actionsResponse.endDate == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileEndDateSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileEndDateSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }                    
                }

                return show;
            },

            showDocument() {
                let show = false;
                
                if (this.hasActionResponse) {

                    if (this.actionsResponse.sendDocument == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileSendDocumentSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileSendDocumentSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }                    
                }

                return show;
            },

            showCheckList() {
                let show = false;
                
                if (this.actionsResponse != null && this.actionsResponse != undefined) {

                    if (this.actionsResponse.checkList == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileCheckListSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileCheckListSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }
                }

                return show;
            },

            showSubContractor() {
                let show = false;
                
                if (this.hasActionResponse) {

                    if (this.actionsResponse.subContractor == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileSubcontractorSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileSubcontractorSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }                    
                }

                return show;
            },

            showUploadPhotos() {
                let show = false;
                
                if (this.hasActionResponse) {

                    if (this.actionsResponse.uploadPhoto == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileUploadPhotoSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileUploadPhotoSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }                    
                }

                return show;
            },

            showForm() {
                let show = false;
                
                if (this.hasActionResponse) {

                    if (this.actionsResponse.form == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileFormSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileFormSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }                    
                }

                return show;
            },

            showNotes() {
                let show = false;
                
                if (this.actionsResponse != null && this.actionsResponse != undefined) {

                    if (this.actionsResponse.notes == 1) {
                        
                        show = true;

                        if (this.hasResponsibleProfile(this.actionsResponse.listIDProfileNotesSelected)) {

                            let listProfiles = this.actionsResponse.listIDProfileNotesSelected.map( item => item.id );

                            show = listProfiles.includes(this.userLoggedGetters.profile.id) || this.isAdmin;
                        }
                    }
                }

                return show;
            }
        },

        watch: {
            async "idAux"() {
                this.clearFields();
				this.getRegisters();
            },

            "actionsRegistersRequest.targetDate" () {
                this.targetDateFormatted = this.formatDate(this.actionsRegistersRequest.targetDate)
            },

            "actionsRegistersRequest.completedDate" () {
                this.completedDateFormatted = this.formatDate(this.actionsRegistersRequest.completedDate)
            },
        },

        methods: {

            hasResponsibleProfile(profile) {
                return profile != null && 
                       profile != undefined &&
                       profile != '';
            },

            clearFields() {
                this.notes = "";
            },

            filterStages() {

                if (this.panelSelected != null && this.panelSelected != undefined) {
                    this.stageSelected = null;
                    this.listConstructionStagesFiltered = this.listConstructionStages.filter(stage => stage.idPanel == this.panelSelected.id);
                }
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            cancel() {
                this.$emit('update:showDialogActivities', false);
            },

            async getRegisters() {
                this.listContractor = await this.$store.dispatch("contractorModule/List");
                this.listConstructionPanel = await this.$store.dispatch("serviceModule/ListConstructionPanel");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");

                this.actionsResponse = null;

                this.documentFiles = [];
                this.documents = [];
                this.activityFilesPhotos = [];
                this.activityPhotos = [];
                                
                if (this.idAction != null && this.idAction != undefined && this.idAction != 0) {
                    let responseAction = await this.$store.dispatch("actionsModule/GetById", this.idAction);
                    if (responseAction.success) {
                        this.actionsResponse = responseAction.result;

                        const responseActionsRegisters = await this.$store.dispatch("actionsModule/GetRegisters", { idServiceProject: this.idServiceProject, idAction: this.idAction });
                        if (responseActionsRegisters.success) {
                            this.actionsRegistersRequest = responseActionsRegisters.result;

                            if (this.actionsRegistersRequest.listNotes == null || this.actionsRegistersRequest.listNotes == undefined) {
                                this.actionsRegistersRequest.listNotes = [];
                            }
                            
                            if (this.actionsRegistersRequest.targetDateFormatted != null && this.actionsRegistersRequest.targetDateFormatted != undefined) {
                                this.actionsRegistersRequest.targetDate = this.parseDate(this.actionsRegistersRequest.targetDateFormatted);
                                this.targetDateFormatted = this.actionsRegistersRequest.targetDateFormatted;
                            }
                            
                            if (this.actionsRegistersRequest.completedDateFormatted != null && this.actionsRegistersRequest.completedDateFormatted != undefined) {
                                this.actionsRegistersRequest.completedDate = this.parseDate(this.actionsRegistersRequest.completedDateFormatted);
                                this.completedDateFormatted = this.actionsRegistersRequest.completedDateFormatted;
                            }
                            
                            if (this.actionsRegistersRequest.deadlineDateFormatted != null && this.actionsRegistersRequest.deadlineDateFormatted != undefined) {
                                this.actionsRegistersRequest.deadlineDate = this.parseDate(this.actionsRegistersRequest.deadlineDateFormatted);
                                this.deadlineDateFormatted = this.actionsRegistersRequest.deadlineDateFormatted;
                            }

                            if (this.actionsRegistersRequest.listEvents != null && this.actionsRegistersRequest.listEvents != undefined) {
                                let listCheckList = this.actionsRegistersRequest.listEvents.filter(evt => evt.eventType == this.EVENT_TYPE.CHECKBOX_LIST);

                                this.checkListSelected = [];
                                if (listCheckList != null && listCheckList != undefined) {
                                    listCheckList.forEach ( item => {
                                        this.checkListSelected.push(item.value);
                                    });
                                }
                            }

                            this.getActivitiesFiles();
                        }
                    }
                }
                
                if (this.idPanel != 0) {

                    let panel = this.listConstructionPanel.filter(pnl => pnl.id == this.idPanel)[0];
                    if (panel != null && panel != undefined) {
                        this.panelSelected = panel
                    }
                }

                if (this.idStage != 0) {

                    await this.filterStages();

                    let stage = this.listConstructionStages.filter(stg => stg.id == this.idStage)[0];
                    if (stage != null && stage != undefined) {
                        this.stageSelected = stage
                    }
                }

                if (this.readOnlyNotes) {
                    this.listNote = await this.$store.dispatch("serviceModule/ListProjectNote", { 
                        idService: 0, 
                        idServiceProject: this.idServiceProject, 
                        onlyCheckExists: false, 
                        idStatus: 0, 
                        idPanel: this.idPanel, 
                        idStage: this.idStage, 
                        idUser: 0 
                    });
                }
            },

            async getActivitiesFiles() {

                let idActionRegister = this.actionsRegistersRequest.id;

                if (this.actionsRegistersRequest.id != 0) {
                    
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.ACTIVITIES.value, idKey: idActionRegister });

                    //DOCUMENTS
                    let listDocuments = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.ACTIVITIES_DOCUMENT.value);  

                    listDocuments.forEach(itemDocument => {

                        this.documents.push({
                            id: itemDocument.id,
                            file: new File([], itemDocument.fileName),
                            fileType: itemDocument.fileType,
                            fileName: itemDocument.fileNameShort,
                            deleted: false
                        })
                    })

                    //EQUIPMENT LOCATION
                    let listActivitiesPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.ACTIVITIES_PHOTOS.value);  
                    listActivitiesPhotos.forEach(itemPhoto => {

                        this.activityPhotos.push({
                            id: itemPhoto.id,
                            file: new File([], itemPhoto.fileName),
                            fileType: itemPhoto.fileType,
                            fileName: itemPhoto.fileNameShort,
                            deleted: false,
                            src: `data:image/jpg;base64,${itemPhoto.file}`
                        })
                    })
                }
            },

            async uploadActivitiesFile(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.ACTIVITIES.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },
            
            async saveActivitiesAllFiles() {

                let allResult = []

                let idActionRegister = this.actionsRegistersRequest.id;

                //DOCUMENTS
                this.documents.forEach(async itemDocument => {

                    let result = await this.uploadActivitiesFile(itemDocument.id, idActionRegister, itemDocument.file, TYPE_DOCUMENT.ACTIVITIES_DOCUMENT.value, itemDocument.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.ACTIVITIES_DOCUMENT.description,
                            message: result.message
                        })
                    }
                })

                //PHOTOS
                this.activityPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadActivitiesFile(itemPhoto.id, idActionRegister, itemPhoto.file, TYPE_DOCUMENT.ACTIVITIES_PHOTOS.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.ACTIVITIES_PHOTOS.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `The [${itemResult.typeDocument}] reported the error: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async save() {

                let idContractor = 0;

                if (this.actionsRegistersRequest.contractorSelected != null && this.actionsRegistersRequest.contractorSelected != undefined) {
                    idContractor = this.actionsRegistersRequest.contractorSelected.id;
                }

                this.actionsRegistersRequest.idServiceProject = this.idServiceProject;
                this.actionsRegistersRequest.idAction = this.idAction;
                this.actionsRegistersRequest.idContractor = idContractor;
                this.actionsRegistersRequest.note = this.notes.trim();
                this.actionsRegistersRequest.listEvents = [];

                if (this.showCheckList && this.checkListSelected != null && this.checkListSelected != undefined) {
                    this.checkListSelected.forEach(item => {
                        this.actionsRegistersRequest.listEvents.push({
                            id: 0,
                            eventType: this.EVENT_TYPE.CHECKBOX_LIST,
                            idKey: 0,
                            value: item
                        })
                    })
                }

                let result = await this.$store.dispatch("actionsModule/CreateUpdateRegisters", this.actionsRegistersRequest);

                if (result.success === true) {

                    this.actionsRegistersRequest.id = result.idActionRegister;

                    if (await this.saveActivitiesAllFiles()) {

                        if (result.success === true) {

                            if (this.actionsRegistersRequest.completedDate != null && this.actionsRegistersRequest.completedDate != undefined && this.actionsRegistersRequest.completedDate != '') {
                                result = await this.updateStep('+');
                            }

                            if (result.success === true) {
                                this.showToast("success", "Success!", result.message);
                                this.$emit('methodConfirmToCall');
                                this.cancel();
                            }
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        if (this.showNotes == true) {

                            if (this.notes.trim() != '') {
                                this.saveNotes()
                            }
                        }

                        this.loading = false;
                        this.hideLoading();
                    }

                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            },

            async updateStep(type) {

                let status = parseInt(this.statusProject);

                if (type == '+') {
                    status++
                }
                else {
                    status--
                }
                
                let updateStatusRequest = {
                    id: this.idServiceProject,
                    status
                };

                let result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);

                if (result.success !== true) {
                    this.showToast("error", "Warning!", result.message);
                }

                return result;
            },            

            async saveNotes() {

                let noteRequest = null;
                let idServiceProject = this.idServiceProject;
                let idProfile = this.userLoggedGetters.profile.id;
                let idPanel = this.idPanel;
                let idStage = this.idStage;
                let idAction = this.idAction;

                if (this.showPanelStages) {
                    if (this.panelSelected != null && this.panelSelected != undefined) {
                        idPanel = this.panelSelected.id;
                    }

                    if (this.stageSelected != null && this.stageSelected != undefined) {
                        idStage = this.stageSelected.id;
                    }
                }

                noteRequest = {
                    id: 0,
                    idServiceProject,
                    idProfile,
                    idPanel,
                    idStage,
                    idAction,
                    description: this.notes.trim(),
                    showToCustomer: 0,
                    expirationDate: null
                }           

                const result = await this.$store.dispatch("serviceModule/AddProjectNote", noteRequest);

                if (result.success === true) {
                    this.cancel();
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            },

            addFileToList(files, listFiles) {

                files.forEach(itemFile => {

                    let fileName = itemFile.name;

                    if (itemFile.name && itemFile.name.length > 12) {
                        fileName = itemFile.name.toString().substring(0, 12) + "..."
                    }

                    let item = {
                        id: 0,
                        file: itemFile,
                        fileName: fileName,
                        deleted: false,
                    }

                    listFiles.push(item);
                });
            },

            async confirmPhotoDelete(itemPhotoToDelete) {

                this.itemPhotoToDelete = itemPhotoToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this image [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPhotoToDelete.deleted = true;
            },
        },

        created() {
            this.getRegisters();
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 170px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .btnToogleNoAccess {
        background-color: transparent !important; 
        color: var(--color__red) !important;
    }
    
    .btnToogleReadOnly {
        background-color: transparent !important; 
        color: var(--color__cinza_escuro) !important;
    }

</style>