<template>
    <div>
        <v-card-text
            :class="`${checkStatusCard(itemCard, itemProject)}`"
            v-if="itemProject != null && itemCard != null"
        >
            <label class="labelMainColor" @click="goToServiceDetails(itemProject.idService)">{{ itemProject.idFormatted }}</label>

            <div
                v-if="checkHoldSituation() == false || userLoggedGetters.profile.systemAdmin == 1"
                class="moreOptions"
            >
                <v-menu
                    left
                    bottom
                    :close-on-content-click="false"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="buttonMoreOptions"
                            color=""
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item
                            v-if="itemCard.moreOptions.jobSpecifics"
                            :to="{ name: 'serviceDetails', params: {id: itemProject.idService} }"
                        >
                            <v-list-item-title><v-icon left>mdi-arrow-expand</v-icon> Job Specifics</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="itemCard.moreOptions.hold && itemProject.situation != 2 && userLoggedGetters.profile.systemAdmin == 1"
                            @click="showDialogHold('H')"
                        >
                            <v-list-item-title><v-icon left>mdi-car-brake-hold</v-icon> Hold</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-else-if="itemCard.moreOptions.hold && userLoggedGetters.profile.systemAdmin == 1"
                            @click="showDialogHold('R')"
                        >
                            <v-list-item-title><v-icon left>mdi-play-circle-outline</v-icon> Resume</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                            v-if="itemCard.moreOptions.workOrder"
                            @click="menuWorkOrder = true;"
                        >
                            <v-list-item-title><v-icon left>mdi-briefcase-outline</v-icon> Work Order</v-list-item-title>
                            
                            <v-menu
                                v-model="menuWorkOrder"
                                right
                                top
                                open-on-hover
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                        x-small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-play
                                    </v-icon>
                                </template>

                                <v-list>
                                    <v-list-item
                                        :to="{ name: 'workOrderForm', params: { id: 0, idPanel: itemCard.idPanel, idServiceProject: itemProject.id } }"
                                    >
                                        <v-list-item-title><v-icon left>mdi-folder-plus-outline</v-icon> New</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="downloadWorkOrder(itemProject.id)"
                                    >
                                        <v-list-item-title><v-icon left>mdi-download</v-icon> Download</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                        </v-list-item>

                        <v-list-item
                            v-if="itemCard.moreOptions.inspection"
                            :to="{ name: 'inspectionForm', params: { id: 0, idPanel: itemCard.idPanel, idStage: itemCard.idStage, idServiceProject: itemProject.id } }"
                        >
                            <v-list-item-title><v-icon left>mdi-magnify-expand</v-icon> Inspection</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="itemCard.moreOptions.showAdvanceButton && (userLoggedGetters.profile.systemAdmin == 1 || userLoggedGetters.profile.constructionSupervisor == 1)"
                            @click="updateStep('+')"
                        >
                            <v-list-item-title><v-icon left>mdi-page-next-outline</v-icon> Advance to the next step</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="itemCard.moreOptions.showBackButton && (userLoggedGetters.profile.systemAdmin == 1 || userLoggedGetters.profile.constructionSupervisor == 1)"
                            @click="updateStep('-')"
                        >
                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to previous step</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="userLoggedGetters.profile.returnCard == 1"
                            @click="showDialogStatus()"
                        >
                            <v-list-item-title><v-icon left>mdi-page-previous-outline</v-icon> Back to...</v-list-item-title>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item
                            v-for="(itemConstruction, index) in listConstructionActions.filter(act => act.idPanel == itemCard.idPanel && act.idStage == itemCard.idStage && act.quickAccessMenu == 1)" :key="index"
                            @click="showDialogActivities(
                                itemProject.id,                         //idServiceProject
                                itemProject.status,                     //statusProject
                                itemConstruction.idPanel,               //idPanel
                                itemConstruction.idStage,               //idStage
                                itemConstruction.id,                    //idAction
                                itemConstruction.labelName,             //title
                                false,                                  //showPanelStages
                                false                                   //readOnlyNotes
                            )"
                        >
                            <v-list-item-title><v-icon left>{{ itemConstruction.icon }}</v-icon> {{ itemConstruction.labelName }} </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            v-if="listConstructionOtherActions.filter(act => act.idPanel == itemCard.idPanel && act.idStage == itemCard.idStage).length > 0"
                            @click="menuExtraActions = true"
                        >
                            <v-list-item-title><v-icon left>mdi-stretch-to-page-outline</v-icon> Other actions...</v-list-item-title>
                            
                            <v-menu
                                v-model="menuExtraActions"
                                right
                                top
                                open-on-hover
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                        x-small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-play
                                    </v-icon>
                                </template>

                                <v-list>
                                    <v-list-item
                                        v-for="(itemConstruction, index) in listConstructionOtherActions.filter(act => act.idPanel == itemCard.idPanel && act.idStage == itemCard.idStage)" :key="index"
                                        @click="showDialogActivities(
                                            itemProject.id,                         //idServiceProject
                                            itemProject.status,                     //statusProject
                                            itemConstruction.idPanel,               //idPanel
                                            itemConstruction.idStage,               //idStage
                                            itemConstruction.id,                    //idAction
                                            itemConstruction.labelName,             //title
                                            false,                                  //showPanelStages
                                            false                                   //readOnlyNotes
                                        )"
                                    >
                                        <v-list-item-title><v-icon left>{{ itemConstruction.icon }}</v-icon> {{ itemConstruction.labelName }} </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <v-card
                class="d-flex background-transparent margin-top-10"
                flat
                tile
            >
                <v-card
                    v-if="itemProject.kindWork == 1"
                    class="pa-2 background-transparent"
                    flat
                    tile
                >
                    <v-tooltip
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-chip
                                v-on="on"
                                color="var(--color__main)"
                                outlined
                                dark
                                small
                            >
                                {{ itemProject.kindWorkFirstLetter }}
                            </v-chip>
                        </template>
                        <span>{{ itemProject.kindWorkDescription }}</span>
                    </v-tooltip>
                </v-card>

                <v-card
                    v-if="checkHoldSituation()"
                    class="pa-2 background-transparent"
                    flat
                    tile
                >
                    <v-tooltip
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-chip
                                v-on="on"
                                :color="getSituationColor()"
                                outlined
                                dark
                                small
                            >
                                {{ itemProject.situationDescription }} - {{ itemProject.dateHoldDays }}
                            </v-chip>
                        </template>
                        <span>{{ itemProject.notesHold }}</span>
                    </v-tooltip>
                </v-card>

                <v-card
                    class="pa-2 background-transparent"
                    flat
                    tile
                >
                    <v-chip
                        :color="getClassificationColor(itemProject.classification)"
                        outlined
                        dark
                        small
                    >
                        {{ itemProject.classification }} - {{ itemProject.classificationDescription }}
                    </v-chip>
                </v-card>
            </v-card>

            <label>{{ itemProject.customerName }}</label>
            <br />
            <label>{{ itemProject.customerAddress }} </label>
            <br />
            <label>{{ itemProject.customerCity }} </label>
            <br v-if="haveGateCode()" />
            <label v-if="haveGateCode()">Gate Code: {{ itemProject.customerAddressGateCode }}</label>
            <br />
            <span>{{ itemProject.customerPhone }}</span>
            <br />
            <span>{{ itemProject.customerEmail }}</span>

            <h5 style="color: #bbb">Contracted on: {{ itemProject.dateContractedFormatted }}</h5>

            <h5 style="color: #bbb">
                Deadline: <strong>{{ getDeadline(itemProject).date }}
                <span :style="getDeadline(itemProject).style">( {{ getDeadline(itemProject).description }} )</span> </strong>
            </h5>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions
            :class="`${checkStatusCard(itemCard, itemProject)}`"
            v-if="itemCard.showSelectSupervisor"
        >
            <v-col 
                cols="12"
            >                            
                <div class="marginInfoBottom" />

                <v-icon left>mdi mdi-account-question-outline</v-icon>
                <label>No supervisor</label>
                <v-tooltip 
                    top
                >
                <template v-slot:activator="{ on }">
                        <v-icon 
                            v-on="on"
                            @click="showDialogSupervisor(itemProject.id)"
                            style="float: right; cursor: pointer;"
                            right 
                        >
                            mdi mdi-account-hard-hat-outline
                        </v-icon>
                    </template>
                    <span>Select a supervisor</span>
                </v-tooltip>
            </v-col>
        </v-card-actions>

        <v-card-actions
            :class="`${checkStatusCard(itemCard, itemProject)}`"
            v-else
        >
            <v-col 
                cols="12"
            >
                <div class="marginInfoBottom" />

                <v-icon color="var(--color__main) !important" left>mdi mdi-account-check-outline</v-icon>
                <label class="userAssigned">{{ itemProject.userNameSupervisor }}</label>
            </v-col>
        </v-card-actions>

        <v-card-actions
            :class="`${checkStatusCard(itemCard, itemProject)}`"
        >
            <v-col 
                class="margin-top"
                cols="6"
                lg="8"
                md="6"
                sm="6"
            >
                <v-icon left>mdi mdi-account-tie</v-icon>
                <label >{{ itemProject.sellerName }}</label>
                
                <br />
                <div class="marginInfoBottom" />

                <v-icon left>mdi mdi-file-sign</v-icon>
                <label>{{ itemProject.nameProject }}</label>
            </v-col>
            <v-col 
                class="contractValue"
                cols="6"
                lg="4"
                md="6"
                sm="6"
            >
                <br />
                <div class="marginInfoBottom" />

                <label>{{ itemProject.contractValueFormatted }}</label>

                <br />
                <div class="marginInfoBottom" />

                <v-menu
                    right
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="buttonMoreInformation"
                            color=""
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi mdi-information-slab-circle-outline</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-card>
                            <v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                                Upcoming Activities
                            </v-card-title>
                            <v-card-text>
                                <v-data-table
                                    style="margin-top: 10px;"
                                    :headers="headersConstructionItems"
                                    :items="listConstructionItems"
                                    :options.sync="optionsConstructionItems"
                                    :loading="loading"
                                    :items-per-page="10"
                                    no-data-text="No Record Found"
                                >
                                </v-data-table>
                            </v-card-text>                                                
                        </v-card>
                    </v-list>
                </v-menu>

            </v-col>
        </v-card-actions>

        <v-card-actions 
            :class="`justify-center ${checkStatusCard(itemCard, itemProject)}`"
        >
            <v-col
                v-if="checkHoldSituation(itemProject)"
                cols="2"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            color="var(--color__red)"
                            left 
                            v-on="on"
                            large    
                        >
                            mdi mdi-close-octagon
                        </v-icon>
                    </template>
                    <span>Hold [{{ itemProject.dateHoldDays }}] - {{ itemProject.notesHold }} </span>
                </v-tooltip>
            </v-col>

            <v-col 
                cols="2"
                v-if="checkIsLate(itemProject)"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            color="var(--color__alert)"
                            v-on="on" 
                            left
                            large
                        >
                            mdi mdi-alert-octagram
                        </v-icon>
                    </template>
                    <span>Late</span>
                </v-tooltip>
            </v-col>
            <v-col 
                v-if="itemProject.showIconBBQ == 1"
                cols="2"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            color="var(--color__construction_dashboard_bbq)"
                            left 
                            v-on="on"
                            large    
                        >
                            mdi mdi-grill-outline
                        </v-icon>
                    </template>
                    <span>Barbecue</span>
                </v-tooltip>
            </v-col>
            <v-col 
                v-if="itemProject.showIconPergola == 1"
                cols="2"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            color="var(--color__construction_dashboard_pergola)"
                            left 
                            v-on="on"
                            large    
                        >
                            mdi mdi-home-roof
                        </v-icon>
                    </template>
                    <span>Pergolas</span>
                </v-tooltip>
            </v-col>

            <v-col
                v-if="itemProject.showIconNotes == 1"
                cols="2"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            color="var(--color__construction_dashboard_bbq)"
                            left 
                            v-on="on"
                            large
                            @click="showDialogActivities(
                                itemProject.id,     //idServiceProject
                                itemProject.status, //statusProject
                                itemCard.idPanel,   //idPanel
                                itemCard.idStage,   //idStage
                                0,                  //idAction
                                'View Note',        //title
                                false,              //showPanelStages
                                true,               //readOnlyNotes
                            )"
                        >
                            mdi mdi-notebook-edit-outline
                        </v-icon>
                    </template>
                    <span>Notes</span>
                </v-tooltip>
            </v-col>

            <v-col 
                v-for="(itemProduct, index) in itemProject.listProductsWithIcons" :key="index"
                cols="2"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            color="var(--color__construction_dashboard_bbq)"
                            left 
                            v-on="on"
                            large    
                        >
                            {{ itemProduct.iconProduct }}
                        </v-icon>
                    </template>
                    <span> {{ itemProduct.productDescription }} </span>
                </v-tooltip>
            </v-col>

            <v-col 
                cols="2"
                v-if="showImportantMessage(itemProject)"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-icon 
                            color="var(--color__alert)"
                            left 
                            v-on="on" 
                            large
                        >
                            mdi mdi-note-alert-outline
                        </v-icon>
                    </template>
                    <span>{{ itemProject.importantMessage }}</span>
                </v-tooltip>
            </v-col>

            <v-col
                cols="2"
            >
                <v-tooltip right>
                    <template v-slot:activator="{ on }">
                        <v-list-item-icon v-on="on">
                            <v-badge
                                :content="itemProject.qtyUnRead"
                                :value="itemProject.qtyUnRead"
                                left
                                overlap
                                color="var(--color__red)"
                            >
                                <v-icon color="var(--color__main)" large @click="goToServiceDetails(itemProject.idService)">mdi mdi-bell-outline</v-icon>
                            </v-badge>
                        </v-list-item-icon>
                    </template>
                    <span>Unviewed notes</span>
                </v-tooltip>
            </v-col>
        </v-card-actions>

        <DialogHold 
            :id="dialogHoldResume.id"
            :type="dialogHoldResume.type"
            v-on:methodConfirmToCall="dialogHoldResume.methodConfirm"
            :showDialogHold.sync="dialogHoldResume.show"
        />
        
        <DialogStatus
            :id="dialogStatus.id"
            :listStatus="dialogStatus.listStatus"
            v-on:methodConfirmToCall="dialogStatus.methodConfirm"
            :showDialogStatus.sync="dialogStatus.show"
        />
        
        <DialogActivities
            :idAux="dialogActivity.idAux"
            :idServiceProject="dialogActivity.idServiceProject"
            :statusProject="dialogActivity.statusProject"
            :idPanel="dialogActivity.idPanel"
            :idStage="dialogActivity.idStage"
            :idAction="dialogActivity.idAction"
            :status="dialogActivity.status"
            :title="dialogActivity.title"
            :showPanelStages="dialogActivity.showPanelStages"
            :readOnlyNotes="dialogActivity.readOnlyNotes"
            v-on:methodConfirmToCall="dialogActivity.methodConfirm"
            :showDialogActivities.sync="dialogActivity.show"
        />
        
        <DialogSelectSupervisor
            :id="dialogSupervisor.id"
            :idStatusToUpdate="dialogSupervisor.idStatusToUpdate"
            v-on:methodConfirmToCall="dialogSupervisor.methodConfirm"
            :showDialogSupervisor.sync="dialogSupervisor.show"
        />
        
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import { orderBy } from '@/utilities/Utils';
    import DialogHold from "@/components/Services/DialogHold";
    import DialogStatus from "@/components/Services/DialogStatus";
    import DialogActivities from "@/components/Services/DialogActivities";
    import DialogSelectSupervisor from "@/components/Services/DialogSelectSupervisor";
    import { PROJECT_STATUS } from "@/utilities/Enums";

	export default {

        mixins: [Helpers],

        components: {
            DialogHold,
            DialogStatus,
            DialogActivities,
            DialogSelectSupervisor
        },

        props: {
			
            id: {
                type: Number,
                default: 0
            },
			
            itemProject: {
                type: Object,
                default: null
            },
			
            itemCard: {
                type: Object,
                default: null
            },
		},
		
        data: () => ({

            dialogHoldResume: {
                id: 0,
                type: "H",
                show: false,
                methodConfirm: ( () => {} ),
            },

            listStatus: [],
            dialogStatus: {
                id: 0,
                listStatus: [],
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogActivity: {
                idAux: 0,
                idServiceProject: 0,
                statusProject: 0,
                idPanel: 0,
                idStage: 0,
                idAction: 0,
                status: 0,
                title: "",
                show: false,
                showPanelStages: false,
                readOnlyNotes: false,
                methodConfirm: ( () => {} ),
            },

            listConstructionActions: [],
            listConstructionOtherActions: [],

            dialogSupervisor: {
                id: 0,
                idStatusToUpdate: 0,
                show: false,
                methodConfirm: ( () => {} ),
            },

            menuWorkOrder: false,
            menuExtraActions: false,
            
            loading: false,

            listConstructionItems: [ 
                { id: 1, userNameRegister: 'Rayane Tavares', dateRegisterFormatted: '03/30/2023 09:12', stageDescription: 'Locates', targetDateFormatted: '04/02/2023 09:00'},
                { id: 2, userNameRegister: 'Rayane Tavares', dateRegisterFormatted: '03/30/2023 11:54', stageDescription: 'Rebar', targetDateFormatted: ''},
            ],

            headersConstructionItems: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Phase", value: "stageDescription", sortable: true },
                { text: "Target", value: "targetDateFormatted", sortable: true },
                { text: "Created By", value: "userNameRegister", sortable: true },
                { text: "Created On", value: "dateRegisterFormatted", sortable: true },
            ],
            
            optionsConstructionItems: { rowsPerPage: 10, page: 1 },

            validations: {
                required: required
            },

            PROJECT_STATUS
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' })
        },

        methods: {

            showImportantMessage(item) {
                return item.importantMessage != null && 
                       item.importantMessage != undefined && 
                       item.importantMessage.toString().trim() != ""
            },

            checkStatusCard(itemCard, itemProject) {

                if (itemProject != null && itemProject.status < itemProject.statusOriginal) {

                    if (itemProject.status == itemCard.idStatusCard) {
                        return 'card-with-problem'
                    }
                    else {
                        return 'card-waiting-solve-problem'
                    }
                }
            },
			
            getClassificationColor (classification) {

                let color = "";

                switch (classification) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__alert)';
                        break;

                    case 3:
                        color = 'var(--color__main)';
                        break;

                    case 4:
                        color = 'var(--color__blue)';
                        break;
                }

                return color
            },
			
            getSituationColor () {

                let color = "";

                switch (this.itemProject.situation) {

                    //OPEN
                    case 1:
                        color = 'var(--color__main)';
                        break;

                    //HOLD
                    case 2:
                        color = 'var(--color__red)';
                        break;

                    //CANCELED
                    case 3:
                        color = 'var(--color__completed)';
                        break;
                }

                return color
            },
            
            haveGateCode() {

                if (this.itemProject != null) {
                    return this.itemProject.customerAddressGateCode != null && this.itemProject.customerAddressGateCode != undefined && this.itemProject.customerAddressGateCode != ''
                }
            },

            goToServiceDetails(id) {
                this.$router.push({ name: "serviceDetails", params: { id } });
            },

            checkHoldSituation() {
                return this.itemProject.situation == 2
            },

            async showDialogHold(type) {
                this.dialogHoldResume = {
                    id: this.id,
                    show: true,
                    type,
                    methodConfirm: ( () => {  
                        this.$emit('methodConfirmToCall');
                    })
                }
            },

            async updateStep(type) {

                let status = parseInt(this.itemProject.status);

                if (type == '+') {
                    status++
                }
                else {
                    status--
                }
                
                const updateStatusRequest = {
                    id: this.id,
                    status
                };

                const result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);

                if (result.success === true) {
                    this.showToast("success", "Success!", result.message);
                    this.$emit('methodConfirmToCall');
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }
            },

            async getLists() {
                let listStatus = await this.$store.dispatch("serviceModule/ListStatus");
                this.listStatus = listStatus.filter(sts => sts.id >= 3 && sts.id <= 16 && [4, 13].includes(sts.id) == false).sort((a, b) => { return orderBy(a, b, 0, 'id') });

                this.listConstructionActions = await this.$store.dispatch("actionsModule/List");
                this.listConstructionOtherActions = this.listConstructionActions.filter(act => act.quickAccessMenu != 1);
            },

            showDialogStatus() {
                this.dialogStatus = {
                    id: this.id,
                    listStatus: this.listStatus,
                    show: true,
                    methodConfirm: ( () => { 
                        this.$emit('methodConfirmToCall');
                    })
                }
            },

            async showDialogActivities(
                idServiceProject,
                statusProject,
                idPanel, 
                idStage, 
                idAction, 
                title, 
                showPanelStages, 
                readOnlyNotes
            ) {                
                this.dialogActivity = {
                    idAux: Math.random(),
                    idServiceProject,
                    statusProject,
                    idPanel: idPanel,
                    idStage: idStage,
                    idAction: idAction,
                    title: title,
                    show: true,
                    showPanelStages,
                    readOnlyNotes,
                    methodConfirm: ( () => { 
                        this.$emit('methodConfirmToCall');
                    })
                }
            },

            async showDialogSupervisor(id) {
                this.dialogSupervisor = {                    
                    id,
                    idStatusToUpdate: this.PROJECT_STATUS.CONSTRUCTION_PREDIG_PRESITE.id,
                    show: true,
                    methodConfirm: ( () => { 
                        this.$emit('methodConfirmToCall');
                    })
                }
            },

            async downloadWorkOrder(id) {

                this.showLoading();

                this.$vanKirkApi.get(`workOrder/download-from-project/${id}`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        console.log('response', response);

                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });

                        var url = window.URL.createObjectURL(blob);
                        window.open(url);

                        this.getRegisters();
                        this.hideLoading();
                    })
                    .catch(() => {
                        this.showToast("error", "Erro!", "No work order found");
                        this.hideLoading();
                    }
                );
            },

            getDeadline(itemProject) {

                let style = 'color: var(--color__pipeline_deadline_ok)';
                
                if (this.checkIsLate(itemProject).days == 2) {
                    style = 'color: var(--color__pipeline_deadline_warning)';
                }
                else if (this.checkIsLate(itemProject).days < 2) {
                    style = 'color: var(--color__pipeline_deadline_over_due)';
                }

                let deadline = {
                    date: '',
                    description: ''
                };

                switch (itemProject.status) {

                    //SALES
                    case this.PROJECT_STATUS.SALES_IN_PROGRESS:
                        deadline = { 
                            date: itemProject.dateScopeWorkStep1Formatted,
                            description: itemProject.dateScopeWorkStep1DeadlineDaysDescription
                        }; 
                        break;

                    case this.PROJECT_STATUS.SALES_WAITING_APPROVAL:
                        deadline = { 
                            date: itemProject.dateScopeWorkStep2Formatted,
                            description: itemProject.dateScopeWorkStep2DeadlineDaysDescription
                        }; 
                        break;

                    case this.PROJECT_STATUS.SALES_FINISH_PROPOSAL:
                        deadline = { 
                            date: itemProject.dateScopeWorkStep3Formatted,
                            description: itemProject.dateScopeWorkStep3DeadlineDaysDescription
                        }; 
                        break;

                    case this.PROJECT_STATUS.SALES_CONTRACTED:
                        deadline = { 
                            date: itemProject.dateScopeWorkStep4Formatted,
                            description: itemProject.dateScopeWorkStep4DeadlineDaysDescription
                        }; 
                        break;

                    //DRAFTING
                    case this.PROJECT_STATUS.DRAFTING_REVIEW:
                        deadline = { 
                            date: itemProject.dateDraftingStep1Formatted,
                            description: itemProject.dateDraftingStep1DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING:
                        deadline = { 
                            date: itemProject.dateDraftingStep2Formatted,
                            description: itemProject.dateDraftingStep2DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING_APPROVAL:
                        deadline = { 
                            date: itemProject.dateDraftingStep3Formatted,
                            description: itemProject.dateDraftingStep3DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING_FINAL_PLAN:
                        deadline = { 
                            date: itemProject.dateDraftingStep4Formatted,
                            description: itemProject.dateDraftingStep4DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING_ENGINEERING:
                        deadline = { 
                            date: itemProject.dateDraftingStep5Formatted,
                            description: itemProject.dateDraftingStep5DeadlineDaysDescription
                        };
                        break;

                    //PERMITTING
                    case this.PROJECT_STATUS.PERMITTING_INCOMING_PROJECTS:
                        deadline = { 
                            date: itemProject.datePermittingStep1Formatted,
                            description: itemProject.datePermittingStep1DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_PLAN_APPROVAL_PENDING:
                        deadline = { 
                            date: itemProject.datePermittingStep2Formatted,
                            description: itemProject.datePermittingStep2DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_READY_SUBMIT:
                        deadline = { 
                            date: itemProject.datePermittingStep3Formatted,
                            description: itemProject.datePermittingStep3DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_PERMIT_REVIEW:
                        deadline = { 
                            date: itemProject.datePermittingStep4Formatted,
                            description: itemProject.datePermittingStep4DeadlineDaysDescription
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_FILE_REVIEW:
                        deadline = { 
                            date: itemProject.datePermittingStep5Formatted,
                            description: itemProject.datePermittingStep5DeadlineDaysDescription
                        };
                        break;
                }
                deadline.style = style;

                return deadline;
            },

            checkIsLate(itemProject) {

                const daysLatedSales = 7;
                const daysLatedDrafting = 2;
                const daysLatedPermitting = 2;

                let late = {
                    isLate: false,
                    days: 0
                };

                switch (itemProject.status) {

                    //SALES
                    case this.PROJECT_STATUS.SALES_IN_PROGRESS:
                        late = { 
                            isLate: itemProject.dateScopeWorkStep1DeadlineDays < daysLatedSales,
                            days: itemProject.dateScopeWorkStep1DeadlineDays
                        };
                        break;
                    case this.PROJECT_STATUS.SALES_WAITING_APPROVAL:
                        late = { 
                            isLate: itemProject.dateScopeWorkStep2DeadlineDays < daysLatedSales,
                            days: itemProject.dateScopeWorkStep2DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.SALES_FINISH_PROPOSAL:
                        late = { 
                            isLate: itemProject.dateScopeWorkStep3DeadlineDays < daysLatedSales,
                            days: itemProject.dateScopeWorkStep3DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.SALES_CONTRACTED:
                        late = { 
                            isLate: itemProject.dateScopeWorkStep4DeadlineDays < daysLatedSales,
                            days: itemProject.dateScopeWorkStep4DeadlineDays
                        };
                        break;

                    //DRAFTING
                    case this.PROJECT_STATUS.DRAFTING_REVIEW:
                        late = { 
                            isLate: itemProject.dateDraftingStep1DeadlineDays < daysLatedDrafting,
                            days: itemProject.dateDraftingStep1DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING:
                        late = { 
                            isLate: itemProject.dateDraftingStep2DeadlineDays < daysLatedDrafting,
                            days: itemProject.dateDraftingStep2DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING_APPROVAL:
                        late = { 
                            isLate: itemProject.dateDraftingStep3DeadlineDays < daysLatedDrafting,
                            days: itemProject.dateDraftingStep3DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING_FINAL_PLAN:
                        late = { 
                            isLate: itemProject.dateDraftingStep4DeadlineDays < daysLatedDrafting,
                            days: itemProject.dateDraftingStep4DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.DRAFTING_ENGINEERING:
                        late = { 
                            isLate: itemProject.dateDraftingStep5DeadlineDays < daysLatedDrafting,
                            days: itemProject.dateDraftingStep5DeadlineDays
                        };
                        break;

                    //PERMITTING
                    case this.PROJECT_STATUS.PERMITTING_INCOMING_PROJECTS:
                        late = { 
                            isLate: itemProject.datePermittingStep1DeadlineDays < daysLatedPermitting,
                            days: itemProject.datePermittingStep1DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_PLAN_APPROVAL_PENDING:
                        late = { 
                            isLate: itemProject.datePermittingStep2DeadlineDays < daysLatedPermitting,
                            days: itemProject.datePermittingStep2DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_READY_SUBMIT:
                        late = { 
                            isLate: itemProject.datePermittingStep3DeadlineDays < daysLatedPermitting,
                            days: itemProject.datePermittingStep3DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_PERMIT_REVIEW:
                        late = { 
                            isLate: itemProject.datePermittingStep4DeadlineDays < daysLatedPermitting,
                            days: itemProject.datePermittingStep4DeadlineDays
                        };
                        break;

                    case this.PROJECT_STATUS.PERMITTING_FILE_REVIEW:
                        late = { 
                            isLate: itemProject.datePermittingStep5DeadlineDays < daysLatedPermitting,
                            days: itemProject.datePermittingStep5DeadlineDays
                        };
                        break;
                }

                return late
            }
        },

        async created() {
            this.getLists();
        }
    };
</script>

<style scoped>

    .v-card__actions {
        padding: 0px;
    }

    .buttonMoreInformation {
        border: none; 
        height: 0px;
    }

    .margin-top {
        margin-top: 0px;
    }

</style>