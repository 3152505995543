<template>
    <div>
        <v-dialog
            v-model="showDialogStatus"
            transition="dialog-top-transition"
            persistent
            width="500"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Select status to back
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Status</label>
                            <v-combobox
                                v-model="statusSelected"
                                :items="listStatus"
                                item-text="statusDescription"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                single-line
                                outlined
                                dense
                            ></v-combobox>

                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },
			
            listStatus: {
                type: Array,
                default: () => { return [] }
            },

			showDialogStatus: {
				default: false
			},
		},
		
        data: () => ({
			
            statusSelected: null,

            validations: {
                required: required
            },
		}),

        methods: {

            cancel() {
                this.$emit('update:showDialogStatus', false);
            },

            async confirm() {

                let statusId = 0;

                if (this.statusSelected != null && this.statusSelected != undefined) {
                    statusId = this.statusSelected.id;
                }

                if (statusId == 0) {
                    this.showToast("error", "Error!", "Please, select a Status to complete the action!");
                }
                else {
                
                    let updateProjectStatus = {
                        id: this.id,
                        status: statusId
                    };

                    let result = await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateProjectStatus);

                    if (result.success) {
                        this.showToast("success", "Success!", "Project status updated successfully!");
                    
                        this.$emit('methodConfirmToCall');
                        this.cancel();
                    }
                    this.cancel();
                }
            }
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>